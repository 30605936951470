import React, {useContext, useEffect, useState} from 'react';
import { useNavigate } from "react-router-dom";
import { UserContext } from "../../utils/UserContext";
import API from "../../api";
import Loader from "../../Loader";
import Button from 'react-bootstrap/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogTitle from '@mui/material/DialogTitle';
import 'react-pdf/dist/Page/AnnotationLayer.css';
import 'react-pdf/dist/Page/TextLayer.css';
import classes from './RecipeView.module.css';

const RecipeView = () => {
  const navigate = useNavigate();
  // const baseURL = 'https://kerenfamilynetapi-f93eeb024d98.herokuapp.com';
  const { userCredentials } = useContext(UserContext);
  const { token } = userCredentials;
  const [recipeData, setRecipeData] = useState({
    title: '',
    source: '',
    tags: [],
    side1: null
  });
  const [previewImage, setPreviewImage] = useState(null);
  const [side1, setSide1] = useState(null);
  // const [pdf1, setPdf1] = useState(null);
  const [side2, setSide2] = useState(null);
  // const [pdf2, setPdf2] = useState(null);

  const [loader, setLoader] = useState(false);
  let urLString = window.location.pathname.replace('/recipes/', '');
  const [recipeID] = useState(urLString.replace('/view', ''));
  const [open, setOpen] = React.useState(false);

  useEffect(() => {
    setLoader(true);

    if (token!==undefined) {
      void API.get(`/recipes/${recipeID}/get`, {
        headers: {'Content-Type': 'application/json', 'Authorization' : 'Bearer '+token}
      })
        .then(({data}) => {
console.log("data: ", data)
          setRecipeData({...data});

          if (data.previewImage!==undefined) {
            // setPreviewImage(btoa(String.fromCharCode(...new Uint8Array(data.previewImage.buffer.data))));
            // setPreviewImage(arrayBufferToBase64(data.previewImage.buffer.data));
            setPreviewImage(data.previewImage);
          }

          // if (data.side1.mimetype==="image/jpg" || data.side1.mimetype==="image/jpeg") {
            // setSide1(btoa(String.fromCharCode(...new Uint8Array(data.side1.buffer.data))));
            // setSide1(arrayBufferToBase64(data.side1.buffer.data));
          if (data.side1!=="") {
            setSide1(data.side1);
          }
          // }
          // else if (data.side1.mimetype==="application/pdf") {
          //   alert("should show PDF here but need to get it from the server");
          //   // // // setPdfFile(btoa(String.fromCharCode(...new Uint8Array(data.images[0].data.data))));
          //   // // setPdfFile(data.images[0].data.data);
          //   // void API.get(`/recipes/${recipeID}/image`, {
          //   //   headers: {'Content-Type': 'application/json', 'Authorization' : 'Bearer '+token}
          //   // })
          //   //   .then(({data}) => {
          //   //     setPdf1(data);
          //   //   }).catch(e => {
          //   //     console.log("error getting recipe data", e);
          //   //   })
          // }

          if (data.side2!=="") {
            // if (data.side2.mimetype==="image/jpg" || data.side2.mimetype==="image/jpeg") {
              // setSide2(btoa(String.fromCharCode(...new Uint8Array(data.side2.buffer))));
              // setSide2(arrayBufferToBase64(data.side2.buffer.data));
              setSide2(data.side2);
            // }
            // else if (data.side2.mimetype==="application/pdf") {
            //   // // // setPdfFile(btoa(String.fromCharCode(...new Uint8Array(data.images[0].data.data))));
            //   // // setPdfFile(data.images[0].data.data);
            //   // void API.get(`/recipes/${recipeID}/image`, {
            //   //   headers: {'Content-Type': 'application/json', 'Authorization' : 'Bearer '+token}
            //   // })
            //   //   .then(({data}) => {
            //   //     setPdf2(data);
            //   //   }).catch(e => {
            //   //     console.log("error getting recipe data", e);
            //   //   })
            // }
          }

          setLoader(false);
        }).catch(e => {
          console.log("error getting recipe data", e);
        })
    }
  }, [token, recipeID])

  // const arrayBufferToBase64 = ( buffer ) => {
  //   var binary = '';
  //   var bytes = new Uint8Array( buffer );
  //   var len = bytes.byteLength;
  //   for (var i = 0; i < len; i++) {
  //     binary += String.fromCharCode( bytes[ i ] );
  //   }
  //   return window.btoa( binary );
  // }

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleDeleteClose = () => {
    setOpen(false);
    handleDelete();
  };

  const handleDelete = () => {
    void API.delete(`/recipes/${recipeID}/delete`, {
      headers: {'Content-Type': 'application/json', 'Authorization' : 'Bearer '+token}
    })
      .then(() => {
        navigate(`/recipes`);
      }).catch(e => {
        console.log("error getting recipe data", e);
      })
  }

  const showTags = (tags) => {
    return Object.entries(tags).map(([key, value]) => {
      if (value==="true") {
        if (key==="morning") {
          key = "בוקר";
        }
        else if (key==="lunch") {
          key = "צהריים";
        }

        return <div key={key}>{key}</div>
      }

      return null;
    })
  }

  return (
    <div className="mainContainer">

      { loader && <Loader /> }

      <h2>{recipeData.title}</h2>

      <section className={classes.splitBox}>
        {
          previewImage!==null && previewImage!=="" && <img src={`https://drive.google.com/thumbnail?id=${previewImage}`} alt={recipeData.title} width={'50%'}/>
        }

        <div>
          <div className={classes.tagBox}>
            <div className={classes.tagBoxTitle}>תווית:</div>
            { showTags(recipeData.tags) }
          </div>

          <div className={classes.tagBox}>
            <div className={classes.tagBoxTitle}>מקור:</div>
            { recipeData.source }
          </div>
        </div>
      </section>

      <div className={classes.imagesBox}>
        <div className="sideOne">
          <p>צד 1:</p>
          <img src={`https://drive.google.com/thumbnail?id=${side1}`} alt={recipeData.title} width="100%"/>
        </div>

        {
          // (side2 !== null || pdf2 !== null) &&
          side2!==null && side2!=="" &&
          <div className="sideOne">
            <p>צד 2:</p>
            {
              // side2 !== null && <img src={`data:image/png;base64,${side2}`} alt={recipeData.title} width="100%"/>
              side2 !== null && <img src={`https://drive.google.com/thumbnail?id=${side2}`} alt={recipeData.title} width="100%"/>
            }
            {
              // pdf2 !== null &&
              // <iframe src={`${baseURL}/recipes/${recipeID}/image`} title="title"
              //         style={{width: '100%', height: '115vh'}}>
              // </iframe>
            }
          </div>
        }

      </div>

      <div className={classes.actionBtn}>
        {/*<Button variant="danger" onClick={handleDelete}>מחיקה</Button>*/}
        <Button variant="danger" onClick={() => {
          handleClickOpen()
        }}>מחיקה</Button>
      </div>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          {"אישור לפני מחיקה"}
        </DialogTitle>
        <DialogActions>
          <Button onClick={handleClose} variant="secondary">סרב</Button>
          <Button onClick={() => {
            handleDeleteClose();
          }} autoFocus variant="danger">
            אשר
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}

export default RecipeView;